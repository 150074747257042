import React, { useContext, useState } from "react";
import { navigate, navigateTo, PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import {
  SEO,
  NameInput,
  ProfessionInput,
  JobTypeSelector,
  MaritalStateSelector,
  ButtonPrimary,
} from "@components";
import { LayoutBildRegistration } from "@components/Structural/RegistrationStep1";
import { Route } from "@interfaces";
import { AppContext, MissionsContext } from "@context";
import { Ilustration } from "@images";
import { IlustrationGoal } from "images/Goal";
import { useAuth } from "@hooks";

const goal: React.FC<PageProps> = () => {
  const classes = useStyles();
  const { openCongratsModal } = useContext(AppContext);
  const { setStepMission } = useContext(MissionsContext);
  const [isName, setName] = useState("");
  const [isState, setState] = useState("");
  const [isJob, setJob] = useState("");
  const [isAddress, setAddress] = useState("");
  const [isPosition, setPosition] = useState("");
  const [isProfession, setProfession] = useState("");
  const [isEstate, setEstate] = useState("");
  const [isPhone, setPhone] = useState("");

  const handleClick = () => {
    setStepMission({ route: Route.mision4, number: 4 });
    openCongratsModal();
  };
  useAuth({ redirectIfNotLogged: true });
  return (
    <>
      <SEO />
      <LayoutBildRegistration title="Tu objetivo" subtitle="" number={5}>
        <section className={classes.container}>
          <div>
            <div className={classes.title}>
              ¿Por qué es importante tener un objetivo?
            </div>
            <span className="text">
              El objetivo es la
              <strong> razón </strong>
              por la cual estás ahorrando e invirtiendo. Es importante tener un
              objetivo porque establece un
              <strong> destino para esa plata</strong>, a la vez que te
              <strong> motiva </strong>a alcanzar la meta que te propones. Un
              objetivo puede ser comprar una casa o viajar a algún lugar del
              mundo.
              <div>
                <strong>¡Puedes tener muchos objetivos distintos!</strong>
              </div>
            </span>
          </div>
          <div className={classes.right}>
            <img src={IlustrationGoal} />
            <ButtonPrimary
              secondaryVersion
              text="Continuar"
              onClick={() => navigateTo(Route.profileMission5)}
            />
          </div>
        </section>
      </LayoutBildRegistration>
    </>
  );
};

export default goal;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    position: "relative",
    height: 529,
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    "& .text": {
      marginTop: "2rem",
      display: "block",
      width: 390,
      "& div": {
        marginTop: "2rem",
      },
    },
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    width: 330,
  },
  right: {
    width: 550,
    justifyContent: "end",
    paddingTop: 390,
    textAlign: "end",
    position: "relative",
    "& img": {
      position: "absolute",
      top: -100,
      left: 120,
      width: 450,
    },
  },
}));
